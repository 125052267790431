import { type Dispatch } from 'redux'
import { getAllUsers, getStudentId, updateStudentId } from '../../../api/services'
import { USERS, STUDENT, STUDENT_UPDATE } from '../../../redux/constants'
import { type AxiosResponse } from 'axios'
import { type UserStudent } from '@/interface'
import { setMessage } from '../../reducer/MessageSnackbar/messageSnackbarReducer'
import { resetStudent } from '../../reducer/Users/updateStudentReducer'

export enum UserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  DIRECTOR = 'DIRECTOR',
  ROOT = 'ROOT',

}
export const getUsers = (role: UserRole) => async (dispatch: any): Promise<any> => {
  dispatch({ type: USERS.USERS_LOADING })
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  try {
    const res: AxiosResponse<any> = await getAllUsers(token, role)
    dispatch({ type: USERS.USERS_SUCCESS, payload: res.data.data })
  } catch (error) {
    dispatch({ type: USERS.USERS_ERROR, payload: error })
  }
}

export const getStudent = (id: string) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: STUDENT.STUDENT_LOADING })
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  try {
    const res: AxiosResponse<any> = await getStudentId(token, id)
    dispatch({ type: STUDENT.STUDENT_SUCCESS, payload: res.data.user })
  } catch (error: any) {
    dispatch({ type: STUDENT.STUDENT_ERROR, payload: error.message })
  }
}

export const updateStudent = (id: string | undefined, payload: UserStudent) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: STUDENT_UPDATE.STUDENT_LOADING_UPDATE })
  try {
    const res: AxiosResponse<any> = await updateStudentId(id, payload)
    dispatch({ type: STUDENT_UPDATE.STUDENT_SUCCESS_UPDATE, payload: res.data.user })
    dispatch(setMessage({ message: 'Mensualidad ajustada correctamente', type: 'success' }))
  } catch (error: any) {
    dispatch({ type: STUDENT_UPDATE.STUDENT_ERROR_UPDATE, payload: error.message })
    dispatch(setMessage({ message: 'Error al ajustar la mensualidad, intente de nuevo o contacte al administrador', type: 'error' }))
  } finally {
    setTimeout(() => {
      dispatch(resetStudent())
    }, 3000)
  }
}
