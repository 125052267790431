import { useEffect } from 'react'
import * as Yup from 'yup'
import { type FormikValues, useFormik } from 'formik'
import { type StudentPayload } from '../../../interface/StudentForm/studentForm.interface'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { createStudentGroup, getOnlyGrp } from '../../../redux/actions'

const useStudentForm = (token: string): { steps: string[], formik: FormikValues, loading: boolean, successGrps: boolean, loadingGrps: boolean, success: boolean, data: any, labelOfFormik: (key: string) => string } => {
  const { loading, success } = useAppSelector((state) => state.createStudentGrpsReducer)
  const { loading: loadingGrps, success: successGrps, data } = useAppSelector((state) => state.grpsOnlyReducer)
  const dispatch = useAppDispatch()
  const steps = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ]

  const validationSchema = Yup.object({
    email: Yup.string().email('Correo electrónico inválido').trim().required('Correo electrónico es obligatorio'),
    curp: Yup.string(),
    name: Yup.string(),
    lastName: Yup.string(),
    address: Yup.string(),
    group_id: Yup.string(),
    phone: Yup.string(),
    password: Yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres').required('Contraseña es obligatoria'),
    emergency_contact: Yup.string(),
    emergencyContact: Yup.object({
      name: Yup.string(),
      phone: Yup.string(),
      email: Yup.string(),
      address: Yup.string(),
      relationship: Yup.string()
    }),
    aditionalContact: Yup.object({
      name: Yup.string(),
      phone: Yup.string(),
      email: Yup.string().email('Correo electrónico inválido').trim(),
      address: Yup.string(),
      relationship: Yup.string()
    }),
    birthEntity: Yup.object({
      city: Yup.string(),
      country: Yup.string()
    }),
    hasLimitation: Yup.object({
      physical: Yup.boolean(),
      mental: Yup.boolean(),
      chronic: Yup.boolean(),
      which: Yup.string().when('physical', (physical, schema) => {
        return physical ? schema : schema
      }),
      description: Yup.string(),
      treatment: Yup.boolean(),
      treatmentDescription: Yup.string().when('treatment', (treatment, schema) => {
        return treatment ? schema : schema
      }),
      controlledMedication: Yup.boolean(),
      medicationDescription: Yup.string().when('controlledMedication', (controlledMedication, schema) => {
        return controlledMedication ? schema : schema
      }),
      whoSuppliesMedication: Yup.string()
    }),
    healthDetailsDto: Yup.object({
      bloodType: Yup.string(),
      alergies: Yup.array().of(Yup.string()),
      size: Yup.string(),
      weight: Yup.string(),
      wearsGlasses: Yup.boolean(),
      wearsHearingAid: Yup.boolean(),
      hasPhysicalLimitation: Yup.boolean(),
      hasMentalLimitation: Yup.boolean(),
      wearsOrthopedicShoes: Yup.boolean()
    }),
    parents: Yup.object({
      mother: Yup.object({
        name: Yup.string(),
        dni: Yup.string(),
        phone: Yup.string(),
        address: Yup.string(),
        zipCode: Yup.string(),
        work: Yup.string(),
        email: Yup.string().email('Correo electrónico inválido').trim(),
        phoneWork: Yup.string(),
        civilStatus: Yup.string()
      }),
      father: Yup.object({
        name: Yup.string(),
        dni: Yup.string(),
        phone: Yup.string(),
        address: Yup.string(),
        zipCode: Yup.string(),
        work: Yup.string(),
        email: Yup.string().email('Correo electrónico inválido').trim(),
        phoneWork: Yup.string(),
        civilStatus: Yup.string()
      })
    })
  })

  const initialValues: StudentPayload = {
    email: '',
    name: '',
    lastName: '',
    address: '',
    group_id: '',
    phone: '',
    password: '',
    emergencyContact: {
      name: '',
      phone: '',
      email: '',
      address: '',
      relationship: ''
    },
    aditionalContact: {
      name: '',
      phone: '',
      email: '',
      address: '',
      relationship: ''
    },
    birthEntity: {
      city: '',
      country: ''
    },
    hasLimitation: {
      physical: false,
      mental: false,
      chronic: false,
      which: '',
      description: '',
      treatment: false,
      treatmentDescription: '',
      controlledMedication: false,
      medicationDescription: '',
      whoSuppliesMedication: ''
    },
    healthDetailsDto: {
      bloodType: '',
      alergies: [],
      size: '',
      weight: '',
      wearsGlasses: false,
      wearsHearingAid: false,
      hasPhysicalLimitation: false,
      hasMentalLimitation: false,
      wearsOrthopedicShoes: false,
      description: ''
    },
    parents: {
      father: {
        name: '',
        dni: '',
        phone: '',
        address: '',
        zipCode: '',
        work: '',
        email: '',
        phoneWork: '',
        civilStatus: ''
      },
      mother: {
        name: '',
        dni: '',
        phone: '',
        address: '',
        zipCode: '',
        work: '',
        email: '',
        phoneWork: '',
        civilStatus: ''
      }
    },
    curp: '',
    multiUser: false,
    numberStudents: 1,
    students: []
  }

  useEffect(() => {
    void dispatch(getOnlyGrp())
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (values.group_id === '') {
        values.group_id = null
      }
      console.log(values)
      void dispatch(createStudentGroup(values, token))
      if (success) {
        setTimeout(() => {
          resetForm()
        }, 2000)
      }
    }
  })

  const labelMap: Record<string, string> = {
    email: 'Correo Electrónico',
    name: 'Nombre',
    lastName: 'Apellido',
    address: 'Dirección',
    group_id: 'Grupo',
    phone: 'Teléfono',
    password: 'Contraseña',
    emergency_contact: 'Contacto de emergencia',
    curp: 'CURP',
    'emergencyContact.name': 'Nombre',
    'emergencyContact.phone': 'Teléfono',
    'emergencyContact.email': 'Correo Electrónico',
    'emergencyContact.address': 'Dirección',
    'emergencyContact.relationship': 'Parentesco',
    'aditionalContact.name': 'Nombre',
    'aditionalContact.phone': 'Teléfono',
    'aditionalContact.email': 'Correo Electrónico',
    'aditionalContact.address': 'Dirección',
    'aditionalContact.relationship': 'Parentesco',
    'birthEntity.city': 'Ciudad de nacimiento',
    'birthEntity.country': 'País de nacimiento',
    'hasLimitation.physical': 'Limitación física',
    'hasLimitation.mental': 'Limitación mental',
    'hasLimitation.chronic': 'Enfermedad crónica',
    'hasLimitation.which': 'Cuál',
    'hasLimitation.description': 'Descripción',
    'hasLimitation.treatment': 'Tratamiento',
    'hasLimitation.treatmentDescription': 'Descripción del tratamiento',
    'hasLimitation.controlledMedication': 'Medicación controlada',
    'hasLimitation.medicationDescription': 'Descripción de la medicación',
    'hasLimitation.whoSuppliesMedication': 'Quién suministra la medicación',
    'healthDetailsDto.bloodType': 'Tipo de sangre',
    'healthDetailsDto.alergies': 'Alergias',
    'healthDetailsDto.size': 'Talla',
    'healthDetailsDto.weight': 'Peso',
    'healthDetailsDto.wearsGlasses': 'Usa lentes',
    'healthDetailsDto.wearsHearingAid': 'Usa aparato auditivo'
  }

  const labelOfFormik = (key: string): string => labelMap[key] || ''

  return { steps, formik, loading, success, successGrps, loadingGrps, data, labelOfFormik }
}

export default useStudentForm

// if (values.emergency_contact === 'mother') {
//   values.emergencyContact.name = values.parents.mother.name
//   values.emergencyContact.phone = values.parents.mother.phone
//   values.emergencyContact.email = values.parents.mother.email
//   values.emergencyContact.address = values.parents.mother.address
//   values.emergencyContact.relationship = 'Madre'
// } else if (values.emergency_contact === 'father') {
//   values.emergencyContact.name = values.parents.father.name
//   values.emergencyContact.phone = values.parents.father.phone
//   values.emergencyContact.email = values.parents.father.email
//   values.emergencyContact.address = values.parents.father.address
//   values.emergencyContact.relationship = 'Padre'
// } else if (values.emergency_contact === 'other') {
//   values.emergencyContact.name = `${values.parents.mother.name} y ${values.parents.father.name}`
//   values.emergencyContact.phone = `${values.parents.mother.phone} y ${values.parents.father.phone}`
//   values.emergencyContact.email = `${values.parents.mother.email} y ${values.parents.father.email}`
//   values.emergencyContact.address = `${values.parents.mother.address} y ${values.parents.father.address}`
//   values.emergencyContact.relationship = 'Ambos'
// }
