import { type StudentsNResult } from '@/interface'
import { STUDENT_ALL } from '../../constants'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IState {
  loading: boolean
  error: null | string
  data: StudentsNResult[] | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: [],
  success: false
}

interface UsersAction {
  type: string
  payload: StudentsNResult[]
}

const getStudentsReducer = createSlice({
  name: 'getStudentsReducer',
  initialState,
  extraReducers: builder => {
    builder.addCase(STUDENT_ALL.STUDENT_ALL_LOADING, (state) => {
      state.loading = true
    })
      .addCase(STUDENT_ALL.STUDENT_ALL_SUCCESS, (state, action: PayloadAction<UsersAction>) => {
        state.loading = false
        state.success = true
        state.data = action.payload as unknown as StudentsNResult[]
      })
      .addCase(STUDENT_ALL.STUDENT_ALL_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetStudents: () => initialState
  }
})

export const { resetStudents } = getStudentsReducer.actions

export default getStudentsReducer.reducer
