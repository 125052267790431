import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { lighten, styled } from '@mui/material/styles'

export default function StylingRowsGrid ({ rows, columns, ...rest }: any): JSX.Element {
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-root': {
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      overflow: 'hidden'
    },
    '& .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: lighten(theme.palette.background.paper, 0.05)
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))

  return (
    <Box sx={{ width: '100%' }}>
      <StyledDataGrid
        rows={rows}
        sortingMode="server"
        filterMode="server"
        columns={columns}
        sortingOrder={['asc', 'desc']}
        rowsLoadingMode="server"
        {...rest}
      />
    </Box>
  )
}
