
import { getStudents } from '../../../api/services'
import { STUDENT_ALL } from '../../../redux/constants'
import { type AxiosResponse } from 'axios'

const getAllStudents = () => async (dispatch: any): Promise<void> => {
  dispatch({ type: STUDENT_ALL.STUDENT_ALL_LOADING })
  try {
    const res: AxiosResponse<any> = await getStudents()
    dispatch({ type: STUDENT_ALL.STUDENT_ALL_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: STUDENT_ALL.STUDENT_ALL_ERROR, payload: error })
  }
}

export { getAllStudents }
