import { useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { GridToolbar, type GridColDef } from '@mui/x-data-grid'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { useSeparateStudentsByGroup } from './hooks/useSeparateStudentsByGroup'
import { Box, Button, FormGroup, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Link, useParams } from 'react-router-dom'
import { DataGrid, ViewStack, BasicModal, Popover } from '../../components'
import { useGroup } from '../../hooks'
import { type UserStudent } from '@/interface'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { updateStudent } from '../../redux/actions'
import LoadingButton from '@mui/lab/LoadingButton'

const Students = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()

  const {
    users,
    loading,
    openModal,
    handleCloseModal,
    student,
    editStudent
  } = useGroup(id)

  const formattedStudents = useSeparateStudentsByGroup(users?.students_id || null)
  const columns: GridColDef[] = [
    {
      field: 'lastName',
      headerName: 'Apellido',
      width: 99,
      renderCell: (params) => (
        <Typography>{params.row.lastName}</Typography>
      )
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.name}</Typography>
      )
    },
    {
      field: 'email',
      headerName: 'Correo',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.email || 'No disponible'}</Typography>
      )
    },
    {
      field: 'curp',
      headerName: 'Curp',
      width: 200,
      renderCell: (params) => (
        <Typography sx={{ textTransform: 'uppercase' }}>{params.row.curp || 'No disponible'}</Typography>
      )
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.phone || 'No disponible'}</Typography>
      )
    },
    {
      field: 'paymentAmount',
      headerName: 'Mensualidad',
      width: 130,
      renderCell: (params) => (
        <Typography>{params.row.paymentAmount === 0 ? 3200 : params.row.paymentAmount || 'No disponible'}</Typography>
      )
    },
    {
      field: 'totalAmount',
      headerName: 'Mensualidad c/d',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.totalAmount === 0 ? 3200 : params.row.totalAmount || 'No disponible'}</Typography>
      )
    },
    {
      field: 'show',
      headerName: 'Ver',
      width: 200,
      renderCell: (params) => (
        <div>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/student/${params.row._id}?group_id=${params.row.group_id || ''}`}
          >
            Ver alumno
          </Link>
        </div>
      )
    },
    {
      field: 'mas',
      headerName: 'Más',
      width: 100,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Popover>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button sx={{ justifyContent: 'flex-start' }} onClick={() => { editStudent(params.row) }} startIcon={<EditRoundedIcon />}>
                Ajustar mensualidad
              </Button>
              <Button sx={{ justifyContent: 'flex-start' }} onClick={() => { editStudent(params.row) }} startIcon={<PersonRemoveRoundedIcon />} color="error">
                Dar de baja
              </Button>
            </Box>
          </Popover>
        </div>
      )
    }
  ]

  return (
    <ViewStack sx={{ padding: '0 12px' }} alignItems='flex-start'>
      <BasicModal open={openModal} handleClose={handleCloseModal}>
        <ComponentToEdit {...student} />
      </BasicModal>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <h1>Alumnos</h1>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          rows={formattedStudents}
          columns={columns}
          getRowId={(row: any) => `${row._id}-${Math.random()}`}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 11
              }
            }
          }}
          pageSizeOptions={[11]}
          disableRowSelectionOnClick
          loading={loading}
        />
        {/* {!loading && formattedStudents.length > 0 && (
        )} */}
      </div>
    </ViewStack>
  )
}

export default Students

const ComponentToEdit = ({ _id, paymentAmount, totalAmount }: UserStudent): JSX.Element => {
  const [type, setType] = useState<string>('percentage')
  const [valueAdjust, setValueAdjust] = useState<number>(0)
  const { loading } = useAppSelector((state: { updateStudentReducer: any }) => state.updateStudentReducer)

  const dispatch = useAppDispatch()

  const adjustPayment = (x: number): number => {
    if (type === 'percentage') {
      if (x === 0) {
        return 3200 - (3200 * (valueAdjust / 100))
      } else {
        return x - (x * (valueAdjust / 100))
      }
    } else {
      if (x === 0) {
        return 3200 - valueAdjust
      } else {
        return x - valueAdjust
      }
    }
  }

  const handleSave = (): void => {
    const payload: UserStudent = {
      discountAmount: valueAdjust,
      totalAmount: adjustPayment(paymentAmount),
      typeDiscount: type,
      paymentAmount: paymentAmount === 0 ? 3200 : paymentAmount
    }
    void dispatch(updateStudent(_id, payload))
  }

  return (
    <Box>
      <Typography textAlign={'center'} variant='h5'>Ajuste de Mensualidad Escolar</Typography>
      <Box mt={5} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} variant='h6'>Mensualidad:</Typography>
          <Typography textAlign={'center'}>{`$ ${paymentAmount === 0 ? 3200 : paymentAmount} mx`}</Typography>
        </Box>
        <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} sx={{ fontWeight: 'bold', fontSize: '14px' }}>Descuento Actual:</Typography>
          <Typography textAlign={'center'}>$ {totalAmount} mx</Typography>
        </Box>
        <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} sx={{ fontWeight: 'bold', fontSize: '14px' }}>Nuevo Descuento</Typography>
          <Typography textAlign={'center'}>$ {adjustPayment(paymentAmount)} mx</Typography>
        </Box>
        <Box mt={5} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography textAlign={'center'} variant='h6'>Ajustar Mensualidad</Typography>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel value="percentage" control={<Radio checked={type === 'percentage'} onChange={() => { setType('percentage') }} />} label="Porcentaje" />
              <FormControlLabel value="fixedAmount" control={<Radio checked={type === 'fixedAmount'} onChange={() => { setType('fixedAmount') }} />} label="Monto fijo" />
            </RadioGroup>
          </FormGroup>
          <TextField label='' type='number' value={valueAdjust} onChange={(e) => { setValueAdjust(Number(e.target.value)) }} />
        </Box>
        {/* <Button disabled={valueAdjust === 0 || loading} onClick={handleSave}
          variant='contained' color='primary' sx={{ marginTop: '20px' }}>
          Guardar
        </Button> */}
        <LoadingButton
          sx={{ marginTop: '20px' }}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={valueAdjust === 0 || loading}
          variant='contained'
          color='primary'
        >
          Guardar
        </LoadingButton>
      </Box>
    </Box>
  )
}
